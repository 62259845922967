html, body {
  background-color: #f5f5f5;
  overflow-x: hidden;
}

h1 {
  color: #0000;
  background-image: linear-gradient(90deg, #000, #354960f2);
  background-clip: text;
  min-height: 1.2em;
  margin-top: .1em;
  display: inline-block;
}

hr {
  border-top: 1px solid #e3e3e3;
  margin-left: 1em;
  margin-right: 1em;
}

.row.content {
  height: 100%;
}

.sidenav {
  background-image: linear-gradient(#35496033, #35496073, #35496080);
  border: 1px solid #3549601a;
  max-width: 18.5em;
  height: 100%;
  padding-bottom: 100%;
  padding-left: 9px;
  padding-right: 2px;
  position: fixed;
}

.navbar {
  background-image: linear-gradient(to right, #182429, #1a272d);
}

.navbar-inverse .navbar-toggle {
  border-color: #ffffff80;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
  background-color: #2c404a;
  border-radius: 3px;
}

.well {
  background-color: #35496003;
  border: 1px solid #3549601a;
  border-radius: 7px;
  padding: 15px;
}

.panel-success > .panel-heading {
  color: #354960;
  background-color: #f5f5f5;
  background-image: linear-gradient(to right, #43b78161, #43b78140);
}

.navbar-toggle {
  margin-top: 1.5em;
}

.nav-and-logo-container {
  width: 22%;
  max-width: 17em;
  height: 100%;
  position: fixed;
}

.logo {
  width: 100%;
  max-width: 15em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.main-content {
  padding-right: 1.2em;
}

@media screen and (width <= 767px) {
  .row.content {
    height: auto;
  }

  .content .main-content {
    margin-top: 5em;
    margin-left: 0%;
    padding-right: 0;
  }

  .mgi-content-container {
    padding-left: .5em;
    padding-right: .5em;
  }

  .well {
    min-height: 20em;
    padding: .5em;
  }
}

.navbar {
  z-index: 99;
  width: 100%;
  min-height: 80px;
  position: fixed;
}

.navbar-brand {
  padding: .4em 0 0;
}

.socials-buttons.socials-sidebar {
  width: 100%;
  margin-left: .5em;
  position: absolute;
  bottom: 1em;
}

.socials-buttons a {
  color: #000;
  margin-right: .4em;
  font-size: 2.5em;
}

.socials-buttons a:focus, .socials-buttons a:hover {
  text-decoration: none;
  transform: scale(1.1);
}

.fa.fa-twitter {
  font-family: sans-serif;
}

.fa.fa-twitter:before {
  content: "𝕏";
  font-size: 1.2em;
}

.main-content {
  width: 100%;
  margin-left: min(18.5em, 25%);
}

.btn-success {
  background-color: #43b781;
}

.welcome-stats {
  text-align: center;
  border-width: .2em;
  border-color: #43b781cc;
  min-height: 11.5em;
  margin-top: 1em;
  margin-bottom: 2em;
  font-size: 1.2em;
}

.welcome-stats i {
  color: #354960;
  font-size: 4.5em;
}

.modal-content {
  border-radius: 7px;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  background-color: #2c404a;
  border-radius: 2px;
}

.nav-pills a {
  color: inherit;
  font-weight: 450;
}

.nav-pills > li > a:hover {
  color: #eee;
  background-color: #43b781a6;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: #333;
  background-color: #eeeeeee6;
}

.alert {
  margin-bottom: 15px;
}
/*# sourceMappingURL=index.8d05ef97.css.map */
