html,
body {
    overflow-x: hidden;
    background-color: whitesmoke;
}

h1 {
    font-size: 33px;
}

hr {
    border-top: 1px solid #e3e3e3;
    margin-left: 1em;
    margin-right: 1em;
}

/* Set height of the grid so .sidenav can be 100% (adjust as needed) */
.row.content {
    height: 100%
}

/* Set gray background color and 100% height */
.sidenav {
    background-image: linear-gradient(rgba(80, 110, 145, 0.1), rgba(80, 110, 145, 0.35), rgba(80, 110, 145, 0.6));
    border: 1px solid rgba(80, 110, 145, 0.1);
    height: 100%;
    padding-bottom: 100%;
    position: fixed;
    max-width: 19em;
}
.navbar {
    background-image: linear-gradient(to right, #182429, #1a272d);
}
.navbar-inverse .navbar-toggle {
    border-color: rgba(255, 255, 255, 0.5);
}
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    background-color: #2c404a;
    border-radius: 3px;
}

.well {
    background-color: rgba(53, 73, 96, 0.01);
    border: 1px solid rgba(53, 73, 96, 0.1);
    border-radius: 7px;
    padding: 15px;
}

.panel-success > .panel-heading {
    background-color: whitesmoke;
    background-image: linear-gradient(to right, rgba(67, 183, 129, 0.23), rgba(67, 183, 129, 0.21));
    border-color: rgba(67, 183, 129, 0.3);
}

.navbar-toggle {
    margin-top: 1.5em;
}

.nav-and-logo-container {
    position: fixed;
    width: 22%;
    max-width: 17em;
    height: 100%;
}

.logo {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 15em;
}

.main-content {
    padding-right: 1.2em;
}

/* On small screens, set height to 'auto' for the grid */
@media screen and (max-width: 767px) {
    .row.content {
        height: auto;
    }

    .content .main-content {
        margin-left: 0%;
        padding-right: 0;
        margin-top: 5em;
    }
    .mgi-content-container {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
    .well {
        min-height: 20em;
        padding: 0.5em;
    }
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 99;
    min-height: 80px;
}

.navbar-brand {
    padding: 0;
    padding-top: 0.4em;
}

.socials-buttons.socials-sidebar {
    bottom: 1em;
    position: absolute;
    margin-left: 0.5em;
    width: 100%;
}

.socials-buttons a {
    font-size: 2.5em;
    color: black;
    margin-right: 0.4em;
}

.socials-buttons a:focus, .socials-buttons a:hover {
    text-decoration: none;
    transform: scale(1.1);
}

.fa.fa-twitter{
	font-family:sans-serif;
}
.fa.fa-twitter::before{
	content:"𝕏";
	font-size:1.2em;
}

.main-content {
    margin-left: min(19em, 25%);
    width: 100%;
}

.btn-success {
    background-color: rgb(67, 183, 129)
}

.welcome-stats {
    min-height: 11.5em;
    margin-bottom: 2em;
    margin-top: 1em;
    text-align: center;
    border-color: rgba(67, 183, 129, 0.8);
    border-width: 0.2em;
    font-size: 1.2em;
}

.welcome-stats i {
    font-size: 4.5em;
    color: rgb(53, 73, 96);
}

.modal-content {
    border-radius: 7px;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
    background-color: #2c404a;
    border-radius: 2px;
}

.nav-pills a {
    color: inherit;
    font-weight: 450;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
    background-color: rgba(53, 73, 96, 0.8);
}

.alert {
    margin-bottom: 15px;
}